// @flow
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Image from '../../components/image';
import GlossaryLink from '../../components/glossary-link';
import type { FrontMatter, ImageType } from '../../utils/types';
import { withFrontMatter } from '../../components';
import CanyonRatingCard from '../../components/canyon-rating-card';
import AllImagesLink from '../../components/all-images-link';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _1: ImageType,
    _2: ImageType,
    _3: ImageType,
    _4: ImageType,
    _5: ImageType,
  },
};

class Blog_2018_03_02_Monkey_Business extends React.Component<Props> {
  render() {
    const { frontMatter, data } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <CanyonRatingCard rating="3AIIS" title="Monkey Business" />
        <p>
          My first canyoneering trip of 2018 kicked off in North Wash with the
          spectacular Monkey Business. I really enjoyed meeting another group of
          talented canyoneers.
        </p>
        <Image
          image={data._1}
          caption="Group picture on the shuttle ride over to Monkey Business"
        />
        <Image image={data._2} caption="Entering the canyon" />
        <p>
          I found the canyon as impressive as ever - fun and challenging{' '}
          <GlossaryLink>down climbs</GlossaryLink>, a dark and twisted rappel
          mid-canyon, and a steep <GlossaryLink>Kelsey exit</GlossaryLink>.
        </p>
        <Image image={data._3} caption="Posing in a narrow section" />
        <Image image={data._4} caption="Near the final rappel" />
        <Image image={data._5} caption="Group shot after the canyon" />
        <AllImagesLink id={frontMatter.id} />
      </Layout>
    );
  }
}

export default withFrontMatter('2018-03-02-monkey-business')(
  Blog_2018_03_02_Monkey_Business
);

export const query = graphql`
  query {
    _1: file(
      relativePath: { eq: "images/blog/2018-03-02-monkey-business/1.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _2: file(
      relativePath: { eq: "images/blog/2018-03-02-monkey-business/2.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _3: file(
      relativePath: { eq: "images/blog/2018-03-02-monkey-business/3.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _4: file(
      relativePath: { eq: "images/blog/2018-03-02-monkey-business/4.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _5: file(
      relativePath: { eq: "images/blog/2018-03-02-monkey-business/5.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
